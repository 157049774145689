/* -- NEWS SLIDER STYLING -- */


#newsCarousel {
  padding-bottom: 2rem;

  .carousel-indicators {
    bottom: -.5rem;
    margin-left: 6%;
    margin-right: 6%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 6%;
  }

  .carousel-inner {
    width: 88%;
    margin: 0 auto;

    .read-more {
      padding-top: 15px;
      padding-bottom: 15px;
      background: rgba($white, 0.2);
      border-radius: $border-radius;

      a:hover {
        text-decoration: none;
      }
    }
  }
}
