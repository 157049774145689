@charset "utf-8";

/* -- Google Fonts -- */
@import url('https://fonts.googleapis.com/css?family=Merriweather:700i');
$font-family-serif: 'Merriweather', serif;
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;600&display=swap');
$font-title-sans-serif: 'Jura', sans-serif;

/* -- Variables -- */
$ipaje-teal: #5EC8D6;
$ipaje-red: #EE302C;
$ipaje-green: #62BC46;
$ipaje-yellow: #E8E100;
$ipaje-orange: #F08000;
$ipaje-darkblue: #0A509F;
$ipaje-purple: #9E70AE;
$ipaje-dark: #585858;
$base-text-color: #333;
$light-gray-bkgd: #f6f6f6;
$ipaje-lightblue: #4A88EEFF;

/* -- font-awesome path override -- */
$fa-font-path: "../fonts";

/* -- Imports -- */
@import "partials/bs-custom";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "partials/globals";
@import "partials/shortcodes";
@import "partials/topbar";
@import "partials/header";
@import "partials/footer";
@import "partials/hero";
@import "partials/banner";
@import "partials/partenaire";
@import "partials/portfolio";
@import "partials/news";
@import "partials/news-slider";
@import "partials/formations";
@import "partials/products";
@import "partials/faq";
@import "partials/home";
@import "partials/landing";
