.hero {
  position: relative;
  width: 100%;
  height: 80vh;
  margin-top: -78px;
  padding-top: 78px;
  background-image: url('/images/hero-xs.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);

  .hero-content {
    padding: 10px 20px;
    background: rgba($white, 0.75);
    text-align: center;

    h1 {
      color: $ipaje-dark;
      font-family: $font-title-sans-serif;
      font-size: 5vw;
      font-weight: 600;
    }

    hr {
      border-top: none;
      height: 4px;
      background: linear-gradient(to right, $ipaje-teal, $ipaje-lightblue);
    }

    h2 {
      color: $ipaje-darkblue;
      font-family: $font-title-sans-serif;
      font-size: 4.5vw;
      font-weight: 400;
    }

    @include media-breakpoint-up(lg) {
      h2 {
        font-size: 3vw;
      }

      h1 {
        font-size: 3.5vw;
      }
    }
  }

  .french-tech {
    position: absolute;
    right: .5rem;
    bottom: .5rem;
    width: 8vw;
    min-width: 50px;
  }
}

@media (orientation: portrait) {
  .hero {
    height: 50vh;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .hero {
    background-image: url('/images/hero-xs@2x.jpg');
  }
}

@include media-breakpoint-up(sm) {
  .hero {
    background-image: url('/images/hero-sm.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .hero {
      background-image: url('/images/hero-sm@2x.jpg');
    }
  }
}

@include media-breakpoint-up(md) {
  .hero {
    height: 60vh;
    background-image: url('/images/hero-md.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .hero {
      background-image: url('/images/hero-md@2x.jpg');
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero {
    height: 60vh;
    background-image: url('/images/hero-lg.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .hero {
      background-image: url('/images/hero-lg@2x.jpg');
    }
  }
}

@include media-breakpoint-up(xl) {
  .hero {
    height: 80vh;
    background-image: url('/images/hero-xl.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .hero {
      background-image: url('/images/hero-xl@2x.jpg');
    }
  }
}
