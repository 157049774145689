footer {
  background-color: $teal;
  color: rgba($body-color, 0.6);

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  h4,
  [class^="fa-"] {
    color: $white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  }

  .read-more {
    padding: 5px;
    border-radius: 5px;

    &:hover {
      background: rgba(255,255,255,0.2);
      text-decoration: none;
    }
  }

  .social-links {
    a {
      width: 40px;
      height: 40px;
      margin: 5px 10px 0 0;
      border-radius: 50%;
      background: rgba($body-color, 0.6);
      transition: background 0.2s linear;

      &:hover {
        background: $white;
        text-decoration: none;

        [class^="fa-"] {
          color: $teal;
        }
      }
    }
  }

  .btn-ipaje {
    background: rgba($body-color, 0.6);
    border: none;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    .submit-btn-icon {
      display: inline-block;
      margin-right: .25rem;
    }

    &:hover {
      background: rgba($body-color, 0.6);
    }

    &:active {
      color: $white !important;
      background: rgba($body-color, 0.8) !important;
    }
  }

  #contact-form {
    position: relative;

    .submit-btn-icon {
      .fa-spinner {
        color: $teal;
      }

      .fa-check {
        color: $ipaje-green;
      }

      .fa-times {
        color: $ipaje-red;
      }
    }

    .reset-btn {
      margin-top: -1rem;
      text-decoration: none;
      padding: 0;
      color: rgba(51, 51, 51, 0.4);

      .svg-inline--fa {
        color: inherit;
        text-shadow: none;
      }

      &:hover {
        text-decoration: none;
        color: rgba(51, 51, 51, 0.6);
      }
    }
  }

  .bottom-bar {
    background: rgba($body-color, 0.6);
    padding: 1rem 15px;
    text-align: center;
    color: $white;
  }
}
