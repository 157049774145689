/* -- ACTUALITES STYLING -- */

.actualites {
  .post-block {
    position: relative;

    .news-anchor {
      position: absolute;
      top: -80px;
      z-index: -10;
    }

    .news-thumb {
      min-width: 160px;
    }
  }

  .light-heading {
    font-weight: 400;
    font-size: 1.6rem;
  }

  .secondary-color {
    color: $gray-600;
  }

  .post-content {
    img {
      margin-bottom: 1rem;
    }
  }
}
