.landing {
  font-size: 1.25rem;
  font-weight: 300;

  h1 {
    @include font-size(3rem);
    font-weight: 300;
    line-height: 1.2;
  }
  h2 {
    @include font-size(2.5rem);
    font-weight: 300;
    line-height: 1.2;
  }
  h3 {
    @include font-size(2rem);
    font-weight: 600;
    line-height: 1.2;
    color: $ipaje-teal;
  }

  .ipaje-spacer {
    display: inline-block;
    width: 100px;
    height: 3px;
    background: $ipaje-teal;
    margin: 0 auto 1rem;
  }
}
