/* -- FAQ STYLING -- */


.f-a-q {
  .nav-tabs {
    .nav-link {
      padding-top: 0.75rem;
      padding-bottom: 0.25rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .card {
    border: none;
    margin-bottom: 0.5rem;

    .card-header {
      border-bottom: none;
      border-radius: 2rem;
      position: relative;
      padding-left: 2rem;

      button {
        color: $teal;
        background: transparent;
        border: none;
        font-weight: 700;

        &:hover {
          color: darken($teal, 15%);
        }

        .fa-plus {
          position: absolute;
          top: 50%;
          left: 14px;
          margin-top: -8px;
          transform: rotateZ(45deg);
          transition: transform 0.2s linear;
        }

        &.collapsed .fa-plus {
          transform: rotateZ(0deg);
        }
      }
    }
  }
}
