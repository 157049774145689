/* -- PRODUCTS STYLING -- */


.product {
  background: $white;
  padding: 2rem;

  h3 {
    font-weight: bold;
  }

  .btn-ipaje {
    margin-bottom: 1rem;
    display: block;
  }

  @include media-breakpoint-up(md) {
    .btn-ipaje {
      display: inline-block;
    }
  }
}

.product-detail {
  h1, h2, h3, h4, h5, h6 {
    padding-top: 1.5rem;
  }

  h5 {
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba($black, .2);
    margin-bottom: 1.5rem;
  }
}
