/* -- HOMEPAGE SASS FILE -- */


.fields {
  position: relative;

  .dot-link {
    position: absolute;
    width: 18px;
    height: 18px;
    top: calc(-1.8rem - 8px);
    left: calc(50% - 9px);
    border-radius: 50%;
    background: $ipaje-teal;
    border: 3px solid $white;
    padding: 0;
    z-index: 10;
  }

  .field-block {
    position: relative;

    .field-link-left {
      position: absolute;
      width: 50%;
      height: 1.5rem;
      top: -1.8rem;
      left: 50%;
      border-top-left-radius: 1rem;
      border: 2px solid $ipaje-teal;
      border-bottom: none;
      border-right: none;
    }

    .field-link-right {
      position: absolute;
      width: 50%;
      height: 1.5rem;
      top: -1.8rem;
      left: 0;
      border-top-right-radius: 1rem;
      border: 2px solid $ipaje-teal;
      border-bottom: none;
      border-left: none;
    }

    p.lead {
      color: $navbar-light-color;
    }
  }
}

.icon-wrapper {
  display: inline-block;
  background: linear-gradient(to top, rgba($ipaje-teal, 1), rgba($ipaje-teal, 0.6));
  color: $white;
  padding: 1.25rem 1rem;
  border-radius: 50%;
  text-align: center;
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.videowrapper {
  position: relative;
  width: 100%;

  img:hover {
    cursor: pointer;
  }

  .play-btn {
    position: absolute;
    width: 80px;
    height: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    border-radius: 50%;
    border: 4px solid $white;
    background: rgba($ipaje-teal, 0.6);
    color: $white;
    font-size: 2.5rem;
    cursor: pointer;
    transition: background 0.2s linear, transform 0.2s linear, box-shadow 0.2s linear;

    .fa-play {
      margin-right: -10px;
    }
  }

  &:hover {
    .play-btn {
      background: rgba($ipaje-teal, 1);
      transform: translateY(-2px);
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    }
  }

  video {
    width: 100%;
  }
}
