/* -- SHORTCODES STYLING -- */

.icontext {
    margin-bottom: 2rem;

    .lettering {
        font-size: 2rem;
        color: $teal;
        border-right: 2px solid $teal;
        padding: 5px 15px;
    }
    @include media-breakpoint-up(xl) {
        .lettering {
            font-size: 3rem;
        }
    }

    .text-content {
        padding: 5px 15px;

        p {
            margin-bottom: 0.25rem;
        }
    }
}

.calltoaction {
    width: 100%;
    border-radius: 1rem;
    border: 2px solid $teal;
    background: $white;
    padding: 1.2rem 1.6rem;
    margin-bottom: 1.5rem;

    .title-wrapper {
        margin-top: -2rem;
        margin-bottom: 1.2rem;
    }

    h5 {
        float: left;
        color: $teal;
        background: $white;
        padding: 0 0.5rem;
    }

    p {
        clear: both;
    }

    &.center {
        width: calc(100% - 2rem);
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .title-wrapper {
            display: flex;
            justify-content: center;
        }

        h5 {
            float: none;
        }
    }
}
@include media-breakpoint-up(lg) {
    .calltoaction {
        width: 50% !important;

        &.right {
            float: right;
            margin-left: 1.5rem;
        }

        &.left {
            float: left;
            margin-right: 1.5rem;
        }
    }
}
@include media-breakpoint-up(lg) {
    .calltoaction {
        width: 40% !important;
    }
}

figure {
    border-left: 3px solid $teal;
    padding: 0.25rem 0.5rem;

    .blockquote {
        font-size: 1.05rem;
        font-style: italic;
        margin-bottom: 1rem;

        p {
            display: inline;
        }

        span {
            font-family: $font-family-serif;
        }
    }

    .blockquote-footer {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.interview {
    font-size: 1.05rem;
    font-style: italic;
    border-left: 3px solid $teal;
    padding: 0.25rem 0.5rem;
    margin-bottom: 1rem;

    .reponse p {
        display: inline;
        margin-bottom: 0;
    }

    b {
        color: $teal;
    }

    span {
        font-family: $font-family-serif;
    }
}

.img-actu {
    display: block;
    padding: 0 2px;
    margin: 0 auto;
}
@include media-breakpoint-up(md) {
    .img-actu {
        margin: 0;
    }

    p .img-actu {
        float: left;
    }

    .img-actu-half {
        width: 50%;
    }

    .img-actu-third {
        width: 33.3333%;
    }
}

.pagination {
    .page-link:hover {
        text-decoration: none;
    }
}
