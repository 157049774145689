.topbar {
  background: $ipaje-teal;
  color: $white;
  font-size: 0.8rem;
  border-bottom: 1px solid darken($ipaje-teal, 5%);

  a {
    padding: 0.25rem 0.75rem;
    color: $white;
    text-decoration: none;

    &:hover {
      color: $ipaje-teal;
      background: $white;
      border-radius: 12px;
      text-decoration: none;
    }
  }
}
