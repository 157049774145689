.head-banner {
  position: relative;
  width: 100%;
  height: calc(78px + 40vh);
  margin-top: -78px;
  padding-top: 78px;
  background-image: url('/images/banner-xs.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-bottom: 2px solid $teal;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);

  .head-banner-content {
    padding: 10px 20px;
    background: rgba($white, 0.75);
    text-align: center;

    h2 {
      color: $ipaje-darkblue;
      font-family: $font-title-sans-serif;
      font-size: 4.5vw;
      font-weight: 400;
    }

    @include media-breakpoint-up(lg) {
      h2 {
        font-size: 3vw;
      }
    }
  }

  .french-tech {
    position: absolute;
    right: .5rem;
    bottom: .5rem;
    width: 8vw;
    min-width: 50px;
  }
}

@media (orientation: portrait) {
  .head-banner {
    height: calc(78px + 25vh);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .head-banner {
    background-image: url('/images/banner-xs@2x.jpg');
  }
}

@include media-breakpoint-up(sm) {
  .head-banner {
    background-image: url('/images/banner-sm.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .head-banner {
      background-image: url('/images/banner-sm@2x.jpg');
    }
  }
}

@include media-breakpoint-up(md) {
  .head-banner {
    background-image: url('/images/banner-md.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .head-banner {
      background-image: url('/images/banner-md@2x.jpg');
    }
  }
}

@include media-breakpoint-up(lg) {
  .head-banner {
    background-image: url('/images/banner-lg.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .head-banner {
      background-image: url('/images/banner-lg@2x.jpg');
    }
  }
}

@include media-breakpoint-up(xl) {
  .head-banner {
    background-image: url('/images/banner-xl.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .head-banner {
      background-image: url('/images/banner-xl@2x.jpg');
    }
  }
}
