/* -- FORMATIONS STYLING -- */


.formations {
  .tab-content {
    .tab-pane {
      h4 {
        color: $ipaje-teal;
        padding-top: 0.5rem;
        margin-bottom: 1.5rem;
      }

      h5 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgba($black, .2);
        margin-bottom: 1.5rem;
      }
    }
  }
}
