header {
  .bg-nav {
    border-bottom: 2px solid $teal;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1) 90%);

    .navbar-toggler {
      border: none;
      color: rgba($black, .6);
      padding: 1.5rem 1rem 0;
      font-size: 1.5rem;

      .fa-bars {
        display: none;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        color: $teal;
      }

      &.collapsed {
        .fa-xmark {
          display: none;
        }

        .fa-bars {
          display: inline;
        }
      }
    }

    .nav-link {
      padding-top: .75rem;
      padding-bottom: .75rem;
      text-align: right;
      font-weight: 500;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-up(md) {
        padding-top: 1.5rem;
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      .dropdown-toggle {
        padding-right: 0;
      }
    }

    .dropdown-toggle:focus {
      outline: none;
    }

    .dropdown-menu {
      border-radius: 0;
      margin: 0.5rem 0 0;
      padding: 0;
      border: none;
      background: rgba($teal, .4);
      text-align: right;

      .dropdown-item {
        padding: 0.5rem 1rem;

        &.active,
        &.active:hover {
          color: $white;
          background-color: $teal;
        }

        &:hover {
          color: $white;
          background-color: rgba($teal, .7);
          text-decoration: none;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .dropdown-menu {
        border-radius: 0;
        top: calc(100% + 14px);
        left: -2px;
        margin: 0;
        padding: 0;
        border: 2px solid #5EC8D6;
        background: $white;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
        border-top: none;
        text-align: left;

        .dropdown-item {
          padding-left: calc(1rem - 2px);
          padding-right: calc(1rem - 2px);

          &.active,
          &.active:hover {
            color: $white;
            background-color: $teal;
          }

          &:hover {
            color: $white;
            background-color: rgba($teal, .7);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
  }
}
