// Color system
$white:   #fff !default;
$black:   #000 !default;
$red:     $ipaje-red;
$yellow:  $ipaje-yellow;
$green:   $ipaje-green;
$teal:    $ipaje-teal;

// Typography
$headings-font-family: $font-title-sans-serif;
$small-font-size: .75rem;

// Links
$link-color:            darken($teal, 10%);
$link-hover-color:      darken($teal, 10%);

// Navs
$nav-pills-border-radius:     2rem;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg:    $teal;

// Navbar
$navbar-light-color:        rgba($black,.6);
$navbar-light-hover-color:  rgba($teal,.8);
$navbar-light-active-color: $teal;

// Forms
$input-border-radius: .25rem;

// pagination
$pagination-hover-bg:               $light-gray-bkgd;
$pagination-active-bg:              $teal;

// Modals
$modal-backdrop-opacity:      .6;
$modal-header-border-color:   $teal;
$modal-header-border-width:   2px;
