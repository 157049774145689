body,
html {
  -webkit-font-smoothing: antialiased;
}

.form-select {
  &:invalid {
    color: $gray-600;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn-ipaje {
  color: $white;
  font-weight: 500;
  background: linear-gradient(to top, rgba($teal, 1), rgba($teal, 0.8));
  border: 1px solid darken($teal, 5%);
  border-radius: 2rem;
  transform: translateY(0);
  box-shadow: none;
  transition: transform 0.2s linear, box-shadow 0.2s linear;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    color: $white;
    transform: translateY(-2px);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }

  &:active {
    color: $white;
    background: $teal;
    margin-top: 0;
    box-shadow: none;
  }

  &.active {
    color: $white;
    border-color: darken($teal, 5%);
    background: darken($teal, 15%);
  }
}

.btn-ipaje-dark {
  color: $white;
  font-weight: 500;
  background: linear-gradient(to top, rgba($secondary, 1), rgba($secondary, 0.8));
  border: 1px solid darken($secondary, 5%);
  border-radius: 2rem;
  transform: translateY(0);
  box-shadow: none;
  transition: transform 0.2s linear, box-shadow 0.2s linear;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    color: $white;
    transform: translateY(-2px);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }

  &:active {
    color: $white;
    background: $secondary;
    margin-top: 0;
    box-shadow: none;
  }

  &.active {
    color: $white;
    border-color: darken($secondary, 5%);
    background: darken($secondary, 15%);
  }
}

.nav-pills {
  .nav-link:hover {
    background-color: rgba($black,.06);
    text-decoration: none;

    &.active {
      background-color: $ipaje-teal;
    }
  }
}

.bg-contrast {
  background: $light-gray-bkgd;
}

.bg-accent {
  background: linear-gradient(to top, rgba($ipaje-teal, 1), rgba($ipaje-teal, 0.8));
  color: rgba($base-text-color, 0.6);

  a,
  a:hover {
    color: inherit;
  }
}

.quote-style {
  font-family: $font-family-serif;
  font-weight: 700;
  font-style: italic;

  &.h2 {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(md) {
    &.h2 {
      font-size: 1.75rem;
    }
  }
  @include media-breakpoint-up(lg) {
    &.h2 {
      font-size: 2rem;
    }
  }
}

.accent-color {
  color: $teal;
}

.lead {
  strong {
    font-weight: 600;
  }
}

em {
  font-weight: 500;
  color: $teal;
}

.ipaje-spacer {
  width: 100px;
  height: 3px;
  background: $teal;
  margin: 1rem auto;
}

.align-content-left,
.align-content-right {
  text-align: center;
}
@include media-breakpoint-up(md) {
  .align-content-left {
    text-align: left;

    .ipaje-spacer {
      margin: 1rem auto 1rem 0;
    }
  }

  .align-content-right {
    text-align: right;

    .ipaje-spacer {
      margin: 1rem 0 1rem auto;
    }
  }
}

.unlead {
  font-size: 1rem;
  font-weight: 400;
}

ul {
  list-style-position: inside;

  li::marker {
    color: $teal;
  }
}

#cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: darken($ipaje-teal, 40%);
  border-top: 2px solid $ipaje-teal;
}
