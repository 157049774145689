/* -- PORTFOLIO STYLING -- */


.clients {
  & > div {
    background: $white;
    border: 2px solid #f6f6f6;

    &.grid-item > button,
    &.grid-item > .no-button {
      margin: 0;
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
    }

    button, .no-button {
      position: relative;
      margin: 0 -15px;
      border: none;
      background: transparent;

      img {
        filter: blur(0) saturate(100%);
        opacity: 1;
        transition: opacity 0.25s linear, filter 0.25s linear;
      }

      & > div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .content {
          color: $base-text-color;
          transform: translateY(20px);
          opacity: 0;
          transition: transform 0.2s linear, opacity 0.2s linear;

          h4 {
            font-weight: 700;
            text-transform: uppercase;
          }

          .ipaje-spacer {
            width: 60px;
            margin: 0.4rem auto;
          }
        }
      }

      &:hover {
        img {
          filter: blur(2px) saturate(0%);
          opacity: 0.1;
        }

        & > div {
          .content {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    & > div {
      height: 270px;
    }
  }
  @include media-breakpoint-up(md) {
    & > div {
      height: 240px;
    }
  }
  @include media-breakpoint-up(lg) {
    & > div {
      height: 320px;
    }
  }
  @include media-breakpoint-up(xl) {
    & > div {
      height: 285px;
    }
  }
  @include media-breakpoint-up(xxl) {
    & > div {
      height: 332px;
    }
  }

  &.filterable {
    width: calc(83.33333% + 30px);
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(sm) {
      width: auto;
      margin-left: -15px;
      margin-right: -15px;
    }

    .grid-item {
      width: 100%;
    }

    @include media-breakpoint-up(sm) {
      .grid-item {
        width: 50%;
      }
    }

    @include media-breakpoint-up(md) {
      .grid-item {
        width: 33.33333%;
      }
    }

    @include media-breakpoint-up(xl) {
      .grid-item {
        width: 25%;
      }
    }
  }
}

.modal-reference {
  h4 {
    .badge {
      display: inline-block;
      font-family: $font-family-base;
      font-size: 50%;
    }
  }

  .modal-body {
    h4, h5, h6 {
      font-weight: bold;
      color: $ipaje-teal;
    }
  }
}
